<template>
  <div class="animated fadeIn">
      <loading
        :active.sync="isLoading"
        :is-full-page="false"
        :color="this.$config.LOADER.color"
        :opacity="this.$config.LOADER.opacity"
        :background-color="this.$config.LOADER.backgroundColor"
        :z-index="10000"
      ></loading>
      <b-row>
        <b-col sm=6>
          <b-card>
        <h4>Өнөөдөр холбогдох харилцагчид</h4>
        <table class="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Овог нэр</th>
              <th>Утас</th>
              <th>Үйлдэл</th>
            </tr>
          </thead>
          <tr v-for="(item, index) in remain_list" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ item.customerId.personId.lastname }} {{ item.customerId.personId.firstname }}</td>
            <td>{{ item.customerId.personId.userId.username }}</td>
            <td>
              <b-button variant="primary" 
                  :to="{name: 'admin.customer.detail',
                    params: { id: item.customerId.id }
                  }"
                  size="sm">
                <i class="fa fa-id-card"></i>
              </b-button>
              <b-button size="sm" variant="success" class="ml-2" @click="remaining(item.id, true)">
                <i class="fa fa-check"></i> Холбогдсон
              </b-button>
            </td>
          </tr>
        </table>
      </b-card>
        </b-col>
        <b-col>
          <b-card>
        <h4>Хугацаандаа холбогдоогүй харилцагчид</h4>
        <table class="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Овог нэр</th>
              <th>Утас</th>
              <th>Товлосон огноо</th>
              <th>Үйлдэл</th>
            </tr>
          </thead>
          <tr v-for="(item, index) in remain_list_before" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ item.customerId.personId.lastname }} {{ item.customerId.personId.firstname }}</td>
            <td>{{ item.customerId.personId.userId.username }}</td>
            <td>{{ item.remainDate | formatD }}</td>
            <td>
              <b-button variant="primary" 
                  :to="{name: 'admin.customer.detail',
                    params: { id: item.customerId.id }
                  }"
                  size="sm">
                <i class="fa fa-id-card"></i>
              </b-button>
              <b-button size="sm" variant="success" class="ml-2" @click="remaining(item.id, false)">
                <i class="fa fa-check"></i> Холбогдсон
              </b-button>
            </td>
          </tr>
        </table>
      </b-card>
        </b-col>
      </b-row>
  </div>
</template>

<script>
import moment from "moment";
import CallList from "@/views/admin/Call/List.vue";

export default {
  name: "dashboardOverdue",
  components: {CallList},
  data: function() {
    return {
      isLoading: false,
      remain_list: [],
      remain_list_before: []
    };
  },
  created() {
    this.loanData()
    this.loanDataBefore()
  },
  methods: {
    loanData: function() {
      this.isLoading = true;
      this.$http
        .get(
          this.$config.API_URL + "CustomerWebService/get_remianing_list",
          {
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            this.isLoading = false;
            console.log(response);
            this.$data.remain_list = response.body.data
          },
          (response) => {
            this.isLoading = false;
            this.showToast("Алдаа", "Алдаа гарсан байана", "danger");
          }
        );
    },
    loanDataBefore: function() {
      this.isLoading = true;
      this.$http
        .get(
          this.$config.API_URL + "CustomerWebService/get_remianing_list_before",
          {
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            this.isLoading = false;
            console.log(response);
            this.$data.remain_list_before = response.body.data
          },
          (response) => {
            this.isLoading = false;
            this.showToast("Алдаа", "Алдаа гарсан байана", "danger");
          }
        );
    },
    remaining: function(_id, _today) {
      this.isLoading = true;
      this.$http
        .post(
          this.$config.API_URL + "CustomerWebService/set_remaining",
          {
            id: _id
          },
          {
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            this.isLoading = false;
            if(_today) {
              this.loanData()
            } else{
              this.loanDataBefore()
            }
          },
          (response) => {
            this.isLoading = false;
            this.showToast("Алдаа", "Алдаа гарсан байана", "danger");
          }
        );
    }
  },
};
</script>
